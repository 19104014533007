<template>
     <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="card">
    <div class="row bg-light d-flex justify-content-center align-items-center">
        <div class="col-lg-12 p-2">
            <label>Cass in hand:</label>
            <div class="mb-3">
                <input type="number" v-model="cash_in_hand" class="form-control w-50" />
            </div>
        </div>
        <div class="col-sm-12 p-2">
            <label>Business Location:</label>
            <div class="mb-3">
                <multiselect
                    v-model="location"
                    :options="locations"
                    :multiple="false"
                    label="name"
                    track-by="name"
                    placeholder="Select..."
                    class="w-50"
                ></multiselect>
            </div>
        </div>
        <div class="row">
            <div class="mb-3">
                <b-button class="btn btn-primary w-25" @click="openRegister()">Open Register</b-button>
            </div>
        </div>
    </div>
  </div>
  </Layout>
</template>
<script>
 import Layout from "@/views/layouts/main";
 import PageHeader from "@/components/page-header";
 import appConfig from "@/app.config";
 import axios from '@/Axiosconfig';
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import Swal from "sweetalert2";

export default {
  name: "Open Register",
  page: {
   title: "Register",
   meta: [
     {
       name: "description",
       content: appConfig.description,
     },
   ],
 },
  props:{
  },
  data() {
    return {
        title: "Register",
       items: [
           {
           text: "Open Register",
           },
           {
           text: "Open Register",
           active: true,
           },
       ],
        cash_in_hand:0,
        business:null,
        location:null,
        locations:[],
    };
  },
  components: {
    Multiselect,
    Layout,
    PageHeader,
  },
  computed: {
  },
  mounted() {
    this.business=JSON.parse(sessionStorage.business_location);
    this.updatearrays();
  },
  methods: {
    updatearrays(){
        axios.get(`locations/?business_name=${this.business.business__name}`)
          .then((res)=>{
            this.locations=res.data['results'].map(loc => ({
            ...loc,
            name: `${this.business.location_name} (${loc.location_id})`
             }));
             this.location=this.locations.find(l=>l.location_id===this.business.location_id);
          })
    },
    openRegister() {
      axios
        .post(`checkregister/`,{'location_id':this.location.location_id,'cash_in_hand':this.cash_in_hand})
        .then((res) => {
          Swal.fire({
            position: "center",
            icon: res.data.icon,
            title: res.data.title,
            html: res.data.msg,
            showConfirmButton: false,
            timer: 1500,
          }).then(()=>{
            this.$router.push({'name':'pos'});
          });
        })
        .cath((e) => {
          Swal.fire({
            position: "center",
            icon: "error",
            title: "" + e,
            showConfirmButton: true,
            timer: 3000,
          });
          this.playBeepSound(this.beep_warning_sound);
        });
    },
  },
};
</script>